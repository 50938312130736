export const FeaturesFlags = {
	SCOPELY_LOGIN: "scopely_login_enabled",
	CLUB_SHOP: "club_shop_enabled",
	CLUB_SHOP_FREE_GIFT: "club_shop_free_gift_enabled",
	MERCH_SHOP: "merch_shop_enabled",
	NEWS: "news_enabled",
	DAILY_BONUS: "daily_bonus_enabled",
	WINNERS: "winners_tab_enabled",
	WATERFALL_SALES: "waterfall_sales_enabled",
	STICKY_DIPS: "sticky_dips_enabled",
	CLUB_GIFT_BONUS: "club_gift_bonus_enabled",
	DIPS: "dips_enabled",
	POIS_BOUNTY: "pois_bounty_enabled",
	POIS_BOUNTY_V2: "pois_bounty_v2_enabled",
	BOGO: "bogo_enabled",
	BOGO_VALIDATION: "bogo_validation_enabled",
	STICKY_TAG: "sale_tags_enabled",
	MAINTENANCE_MODE: "maintenance_mode",
	ENDLESS_OFFER: "endless_offer_enabled",
	FIRST_PURCHASE_BONUS: "first_purchase_bonus_enabled",
	DRIP_OFFER: "drip_offer_enabled",
	BOUNCY_CHEST: "bouncy_chest_enabled"
};
